import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lvl3',
  templateUrl: './lvl3.component.html',
  styleUrls: ['./lvl3.component.css']
})
export class Lvl3Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
